export const preparePathParams = (params: { project_id?: string; vendor_id?: string; status?: string }) => {
  if (Object.keys(params).length === 0) {
    return '';
  }
  return Object.keys(params)
    .reduce((acc, key) => {
      if (params[key as keyof typeof params]) {
        acc.push(`${key}=${params[key]}`);
      }
      return acc;
    }, [] as string[])
    .join('&');
};

export const isValidRedirect = (url) => {
  // Verify if the URL starts with a single '/' and does not contain '//' or 'http'
  return typeof url === 'string' && url.startsWith('/') && !url.startsWith('//') && !url.includes('://');
};

export const sanitizeRedirect = (url) => {
  // Remove '//' initially if present, then remove 'http(s)://' if present
  return url.replace(/^(\/\/|http(s)?:\/\/)/, '/');
};
