import Stack from '@mui/material/Stack';

import { MenuItemProps } from '@global-components/AppMenu/types';

import dotsLoading from '@global-assets/images/dots-loading.svg';

import SubProjectItem from '@global-components/AppMenu/components/MenuProject/ProjectItem';

import './styles.scss';

const ProjectList = ({
  projects,
  isReady,
  isExpanded,
  isCondensed,
  isParent
}: {
  projects?: MenuItemProps[];
  isReady: boolean;
  isExpanded: boolean;
  isCondensed: boolean;
  isParent?: boolean;
}) => {
  return (
    <ul
      className={`
        app-menu-project-list
        ${isParent ? 'app-menu-project-list__parent' : ''}
        ${isExpanded && !isCondensed ? 'app-menu-project-list--expanded' : ''}
        ${isCondensed && isParent ? 'app-menu-project-list__parent--condensed' : ''}
      `}>
      {isReady ? (
        (projects ?? [])?.map((child) => (
          <SubProjectItem
            key={child.path}
            isParent={isParent}
            project={{ ...child, childrenReady: isReady }}
            isCondensed={isCondensed}
          />
        ))
      ) : (
        <li data-testid="app-menu-menu-item-loading">
          <Stack justifyContent="center">
            <img src={dotsLoading} alt="loading" className="new-version-loading" height="25" />
          </Stack>
        </li>
      )}
    </ul>
  );
};

export default ProjectList;
