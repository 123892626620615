/* eslint-disable no-console */
import { useQuery, useMutation } from '@tanstack/react-query';
import accountingAPI from '@global-apis/accounting';
import CostCode, { CostCodeCreateData, CostCodeUpdateData } from '@global-interfaces/CostCode';

export const useCostCodeCreate = (options = {}) =>
  useMutation({ mutationFn: (data: CostCodeCreateData) => accountingAPI.costCode.create(data), ...options });

export const useCostCodeUpdate = (id: number, options = {}) =>
  useMutation({ mutationFn: (data: CostCodeUpdateData) => accountingAPI.costCode.update(id, data), ...options });

// it will return only cost codes being not parent. Used on cost code searcher/selector
export const useCostCodesWithoutParent = (options = {}) =>
  useQuery<CostCode[], Error>({
    queryKey: ['cost-codes-without-parent'],
    queryFn: () => {
      try {
        return accountingAPI.costCode.getAllWithoutParent();
      } catch (err) {
        console.log('error when fetching cost codes without parent', err);
        throw err;
      }
    },
    ...options
  });

// it will return all cost codes being parent or not. Used on budget list
export const useCostCodesWithParent = (options = {}) =>
  useQuery<CostCode[], Error>({
    queryKey: ['cost-codes-with-parent'],
    queryFn: () => {
      try {
        return accountingAPI.costCode.getAllWithParent();
      } catch (err) {
        console.log('error when fetching cost codes without parent', err);
        throw err;
      }
    },
    ...options
  });

export const invalidateCostCodesWithoutParent = (queryClient) =>
  queryClient.invalidateQueries({ queryKey: ['cost-codes-without-parent'] });

export const invalidateCostCodesWithParent = (queryClient) =>
  queryClient.invalidateQueries({ queryKey: ['cost-codes-with-parent'] });
