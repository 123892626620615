import { useEffect } from 'react';
import { useLocation, Navigate, Outlet, PathRouteProps } from 'react-router-dom';

import { useAuth } from '@global-contexts/auth';
import { useGTMContext } from '@global-contexts/gtm';
import { useVersioning } from '@global-contexts/versioning';

import FullScreenLoading from '@global-components/FullScreenLoading';
import AuthenticatedLayout from '@global-layouts/Authenticated';

type Role = 'admin' | 'manager';

interface PrivateRouteProps extends PathRouteProps {
  roles: Role[];
}

const PrivateRoute = ({ roles = [], ...rest }: PrivateRouteProps) => {
  const location = useLocation();
  const {
    started,
    loading: isLoadingAuth,
    authTokenRefreshChecking,
    user,
    isAuthenticated,
    company,
    isFlagsReady
  } = useAuth();
  const { isNewReview } = useVersioning();
  const { sendDataLayer } = useGTMContext();
  const toLogin = location.pathname !== '/' ? `/login?redirectTo=${location.pathname}` : '/login';

  useEffect(() => {
    document.title = 'inBuild';
  }, []);

  useEffect(() => {
    if (user && company.name && company.id) {
      sendDataLayer({
        event: 'page-load',
        page_url: location.pathname
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, user, company]);

  // Check if the JWT token needs to be refreshed by Auth Context. If it does, wait for it to finish. It checks only once on the first render/load
  if (!authTokenRefreshChecking) {
    return FullScreenLoading();
  }

  if (!isAuthenticated() && !started && !isLoadingAuth) {
    return <Navigate to={toLogin} />;
  }

  if (started && !isAuthenticated()) {
    return <Navigate to={toLogin} />;
  }

  if (isAuthenticated() && !started && !isLoadingAuth) {
    return FullScreenLoading();
  }
  if (!started && isLoadingAuth) {
    return FullScreenLoading();
  }
  if (!isFlagsReady) {
    return FullScreenLoading();
  }

  if (roles.length && !roles.some((role) => user?.permissions?.includes(role))) {
    return <Navigate to="/" />;
  }

  if (['/inbox/new-invoice/:id', '/inbox/new-review/:id'].includes(rest.path!) && !isNewReview) {
    return <Navigate to="/" />;
  }

  if (user) {
    return (
      <AuthenticatedLayout>
        <Outlet />
      </AuthenticatedLayout>
    );
  }

  return <Navigate to="/login" />;
};

export default PrivateRoute;
