import { ElementType, lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import * as Sentry from "@sentry/react";

import { useAuth } from '@global-contexts/auth';

import FullScreenLoading from '@global-components/FullScreenLoading';

import PrivateRoute from '@root/routes/privateRoute';
import { ADMIN, MANAGER } from '@global-utils/defaultValues';

const Login = lazy(() => import('@global-pages/auth/Login'));
const Logout = lazy(() => import('@global-pages/auth/Logout'));
const ForgotPassword = lazy(() => import('@global-pages/auth/ForgotPassword'));
const Register = lazy(() => import('@global-pages/auth/Register'));
const NewPassword = lazy(() => import('@global-pages/auth/NewPassword'))
const VendorPayoutForm = lazy(() => import('@global-pages/auth/VendorPayoutForm'))

const NewInboxList = lazy(() => import('@global-pages/apps/Inbox/components/Lists'));
const Inbox = lazy(() => import('@global-pages/apps/Inbox/Invoice'));
const InboxDetail = lazy(() => import('@global-pages/apps/Inbox/InvoiceDetail'));
const ApprovalsList = lazy(() => import('@global-pages/apps/Approvals/List'));
const InboxReviewDetail = lazy(() => import('@global-pages/apps/Inbox/ReviewDetail'));

const NewInbox = lazy(() => import('@global-pages/apps/NewInbox/Invoice'));
const NewInboxDetail = lazy(() => import('@global-pages/apps/NewInbox/InvoiceDetail'));

const NewInboxReview = lazy(() => import('@global-pages/apps/NewInbox/Review'));
const NewInboxReviewDetail = lazy(() => import('@global-pages/apps/NewInbox/ReviewDetail'));

const AccountsPayable = lazy(() => import('@global-pages/apps/AccountsPayable'));

const ProjectDetails = lazy(() => import('@global-pages/apps/Projects/'));

const InvoiceDetail = lazy(() => import('@global-pages/apps/General/InvoiceDetail'));
const PurchaseOrderDetail = lazy(() => import('@global-pages/apps/General/PurchaseOrderDetail'));
const ChangeOrderDetail = lazy(() => import('@global-pages/apps/General/ChangeOrderDetail'));
const OwnerChangeOrderDetail = lazy(() => import('@global-pages/apps/General/OwnerChangeOrderDetail'));

const SettingsSettings = lazy(() => import('@global-pages/settings/general/'));
const SettingsProfile = lazy(() => import('@global-pages/settings/profile/'));
const SettingsCompany = lazy(() => import('@global-pages/settings/company/'));
const SettingsEmailForwarding = lazy(() => import('@global-pages/settings/emailForwarding'));
const SettingsCompanyCards = lazy(() => import('@global-pages/settings/companyCards'));
const SettingsPlaidConnections = lazy(() => import('@global-pages/settings/plaidConnections'));
const SettingsUsers = lazy(() => import('@global-pages/settings/users/'));
const SettingsProjects = lazy(() => import('@global-pages/settings/projects/'));
const SettingsVendorList = lazy(() => import('@global-pages/settings/vendors/'));
const SettingsVendorCreate = lazy(() => import('@global-pages/settings/vendors/create'));
const SettingsVendorEdt = lazy(() => import('@global-pages/settings/vendors/edit'));
const SettingsCostCodeList = lazy(() => import('@global-pages/settings/costCode/'));
const WorkflowsSettings = lazy(() => import('@global-pages/settings/workflows/'));
const SettingsPaymentMethods = lazy(() => import('@global-pages/settings/paymentMethods'));
const SettingsPaymentCreditCard = lazy(() => import('@global-pages/settings/paymentMethods/CreditCards/ManageCreditCards'));
const SettingsPaymentEditCreditCard = lazy(() => import('@global-pages/settings/paymentMethods/CreditCards/EditCreditCards'));
const SettingsPaymentEditBankAccount = lazy(() => import('@global-pages/settings/paymentMethods/BankAccounts/EditBankAccount'));
const SettingsPaymentBankAccounts = lazy(() => import('@global-pages/settings/paymentMethods/BankAccounts/ManageBankAccounts'));

const SageSync = lazy(() => import('@global-pages/apps/Sage'));
const QuickBooksSync = lazy(() => import('@global-pages/apps/QuickBooks'));
const QuickBooksDesktopSync = lazy(() => import('@global-pages/apps/QuickBooksDesktop'));
const QuickBooksDesktopMacSync = lazy(() => import('@global-pages/apps/QuickBooksDesktopMac'));
const ProcoreSync = lazy(() => import('@global-pages/apps/Procore'));
const QuickBooksTimeSync = lazy(() => import('@global-pages/apps/QuickBooksTime'));
const ClockSharkSync = lazy(() => import('@global-pages/apps/ClockShark'));
const ProcoreTimecardSync = lazy(() => import('@global-pages/apps/ProcoreTimecard'));
const QuickBooksDesktopTimeSync = lazy(() => import('@global-pages/apps/QuickBooksDesktopTime'));
const DropboxSync = lazy(() => import('@global-pages/apps/Dropbox'));
const DocuSignSync = lazy(() => import('@global-pages/apps/DocuSign'));
const SageIntacctSync = lazy(() => import('@global-pages/apps/SageIntacct'));

const PaymentTransactions = lazy(() => import('@global-pages/apps/PaymentTransactions'));
const CompanyCardTransactions = lazy(() => import('@global-pages/settings/companyCards/Transactions'));
const PlaidConnectionTransactions = lazy(() => import('@global-pages/settings/plaidConnections/Transactions'));

const NewDocuSignSync = lazy(() => import('@global-pages/apps/ERP/DocuSign'));

const NotFound = lazy(() => import('@global-pages/NotFound'));

const LinkExpired = lazy(() => import('@global-pages/LinkExpired'));

const LazyRoute = ({ title, component: Component }: { title: string; component: ElementType; }) => {
  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <Suspense fallback={<FullScreenLoading />}>
      <Component />
    </Suspense>
  );
};

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const AppRoutes = () => {
  const { user, flags } = useAuth();

  return (
    <BrowserRouter>
      <SentryRoutes>
        <Route path='/login' element={<LazyRoute title='Login' component={Login} />} />
        <Route path='/logout' element={<LazyRoute title='Logout' component={Logout} />} />
        <Route path='/forgot-password' element={<LazyRoute title='Forgot Password' component={ForgotPassword} />} />
        <Route path='/register/:token?' element={<LazyRoute title='Register' component={Register} />} />
        <Route path='/reset-password/:token' element={<LazyRoute title='Reset Password' component={NewPassword} />} />
        <Route path='/vendor-register/:token' element={<LazyRoute title='Vendor Register' component={VendorPayoutForm} />} />
        <Route path='/link-expired' element={<LazyRoute title='Link Expired' component={LinkExpired} />} />
        
        {/* PRIVATE ROUTES */}
        <Route path='/' element={<PrivateRoute roles={[ADMIN, MANAGER]} /> }>
          <Route
            index
            element={<LazyRoute title='Inbox' component={NewInboxList} />}
          />
          <Route path='/inbox'>
            <Route path='review'>
              <Route path=':id' element={<LazyRoute title='Invoice Review' component={InboxReviewDetail} /> } />
            </Route>
            <Route path='new-review' element={<LazyRoute title='Invoice Review' component={NewInboxReview} /> }>
              <Route path=':id' element={<LazyRoute title='Invoice Review' component={NewInboxReviewDetail} /> } />
            </Route>
          </Route>
          <Route path='/accounts-payable' element={<LazyRoute title='Accounts Payable' component={AccountsPayable} />} />
          <Route path='/projects/:projectId' element={<LazyRoute title='Project Detail' component={ProjectDetails} />} />
          <Route path='/invoice/:id' element={<LazyRoute title='Invoice Detail' component={InvoiceDetail} />} />
          <Route path='/purchase-order/:id' element={<LazyRoute title='Contract Detail' component={PurchaseOrderDetail} />} />
          <Route path='/change-order/:id' element={<LazyRoute title='Change Order Detail' component={ChangeOrderDetail} />} />
          {flags.ownerChangeOrder && <Route path='/owner-change-order/:id' element={<LazyRoute title='Owner Change Order Detail' component={OwnerChangeOrderDetail} />} />}
          <Route path='/settings'>
            <Route path='general' element={<LazyRoute title='Settings' component={SettingsSettings} />} />
            <Route path='profile' element={<LazyRoute title='Profile' component={SettingsProfile} />} />
            <Route path='projects' element={<LazyRoute title='Project List' component={SettingsProjects} />} />
            <Route path='vendors' element={<LazyRoute title='Vendor List' component={SettingsVendorList} />}>
              <Route path='create' element={<LazyRoute title='Vendor Creation' component={SettingsVendorCreate} />} />
              <Route path='edit/:id' element={<LazyRoute title='Vendor Edition' component={SettingsVendorEdt} />} />
            </Route>
            <Route path='company-cards'>
              <Route index element={<LazyRoute title='Company Cards' component={SettingsCompanyCards} />} />
              <Route path='transactions/:id' element={<LazyRoute title='Company Card Transactions' component={CompanyCardTransactions} />} />
            </Route>
            <Route path='plaid-connections'>
              <Route index element={<LazyRoute title='Plaid Connections' component={SettingsPlaidConnections} />} />
              <Route path='transactions/:tab/:id/:pm_id' element={<LazyRoute title='Plaid Connection Transactions' component={PlaidConnectionTransactions} />} />
              <Route path='transactions/:tab/:id' element={<LazyRoute title='Plaid Connection Transactions' component={PlaidConnectionTransactions} />} />
            </Route>
          </Route>
        </Route>

        {/* PRIVATE ROUTES - ADMIN */}
        <Route path='/' element={<PrivateRoute roles={[ADMIN]} /> }>
          <Route path='/approvals' element={<LazyRoute title='Approvals' component={ApprovalsList} />} />
          <Route path='/inbox'>
            <Route path='invoice' element={<LazyRoute title='Invoice Review' component={Inbox} /> }>
              <Route path=':id' element={<LazyRoute title='Invoice Review' component={InboxDetail} /> } />
            </Route>
            <Route path='new-invoice' element={<LazyRoute title='Invoice Review' component={NewInbox} /> }>
              <Route path=':id' element={<LazyRoute title='Invoice Review' component={NewInboxDetail} /> } />
            </Route>
          </Route>

          <Route path='/settings'>
            <Route path='company' element={<LazyRoute title='Company' component={SettingsCompany} />} />
            <Route path='email-forwarding' element={<LazyRoute title='Email Forwarding' component={SettingsEmailForwarding} />} />
            <Route path='users' element={<LazyRoute title='User List' component={SettingsUsers} />} />
            <Route path='cost-codes' element={<LazyRoute title='Cost Code List' component={SettingsCostCodeList} />} />
             <Route path='workflows' element={<LazyRoute title='Workflows' component={WorkflowsSettings} />} />
            <Route path='payment-methods'>
              <Route index element={<LazyRoute title='Payment Method List' component={SettingsPaymentMethods} />} />
              <Route path='credit-cards'>
                <Route index element={<LazyRoute title='Credit Card List' component={SettingsPaymentCreditCard} />} />
                <Route path=':id' element={<LazyRoute title='Credit Card Edition' component={SettingsPaymentEditCreditCard} />} />
              </Route>
              <Route path='bank-accounts'>
                <Route index element={<LazyRoute title='Bank Account List' component={SettingsPaymentBankAccounts} />} />
                <Route path=':id' element={<LazyRoute title='Bank Account Edition' component={SettingsPaymentEditBankAccount} />} />
              </Route>
            </Route>
          </Route>

          <Route path='/erp'>
            <Route path='sage' element={<LazyRoute title='App - Sage' component={SageSync} />} />
            <Route path='quickbooks' element={<LazyRoute title='App - Quickbooks' component={QuickBooksSync} />} />
            <Route path='quickbooks-desktop' element={<LazyRoute title='App - Quickbooks Desktop' component={QuickBooksDesktopSync} />} />
            <Route path='quickbooks-desktop-mac' element={<LazyRoute title='App - Quickbooks Mac Desktop' component={QuickBooksDesktopMacSync} />} />
            <Route path='procore' element={<LazyRoute title='App - Procore' component={ProcoreSync} />} />
            <Route path='quickbooks-time' element={<LazyRoute title='App - Quickbooks Time' component={QuickBooksTimeSync} />} />
            <Route path='clockshark' element={<LazyRoute title='App - Clockshark' component={ClockSharkSync} />} />
            <Route path='procore-timecard' element={<LazyRoute title='App - Procore Timecard' component={ProcoreTimecardSync} />} />
            <Route path='quickbooks-desktop-payroll' element={<LazyRoute title='App - Quickbooks Desktop Payroll' component={QuickBooksDesktopTimeSync} />} />
            <Route path='dropbox' element={<LazyRoute title='App - Dropbox' component={DropboxSync} />} />
            <Route path='docusign' element={<LazyRoute title='App - Docusign' component={DocuSignSync} />} />
            <Route path='intacct' element={<LazyRoute title='App - Intacct' component={SageIntacctSync} />} />
          </Route>

          {flags.paymentTransactions && <Route path='/payment-transactions' element={<LazyRoute title='Payment Transactions' component={PaymentTransactions} />} />}
          {user?.company_feature_flags?.new_docusign && <Route path='/new-erp/docusign' element={<LazyRoute title='App - Docusign' component={NewDocuSignSync} />} />}
        </Route>

        <Route path='*' element={<LazyRoute title='Not Found' component={NotFound} />} />
      </SentryRoutes>
    </BrowserRouter>
  );
};

export default AppRoutes;
