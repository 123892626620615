import agaveAPI from '@global-apis/agave';

declare global {
  interface Window {
    AgaveLink: {
      openLink: (options: { linkToken: string; onSuccess: (publicToken: string) => void }) => void;
    };
  }
}

export const connectAgaveConnector = async (companyId: number) => {
  const getLinkTokenFromServer = async (reference_id: number | undefined) => {
    return agaveAPI.token.create(String(reference_id)).then((res) => res.data.link_token);
  };

  const getAgaveAccountWithCompanyId = async (companyId: number | undefined) => {
    return agaveAPI.admin.getAccount(companyId).then((res) => res.data.id);
  };

  const sendPublicTokenToServer = async (public_token: string, account_id: string) => {
    return agaveAPI.token.public(public_token, account_id);
  };

  const accountID = await getAgaveAccountWithCompanyId(companyId);
  return getLinkTokenFromServer(companyId).then((link_token) => {
    window.AgaveLink.openLink({
      linkToken: link_token,
      onSuccess: (publicToken: string) => sendPublicTokenToServer(publicToken, accountID)
    });
  });
};

export const downloadAgaveScript = () => {
  return window.open('http://cdn.agaveapi.com/on-prem-agent-releases/Agave%20Connector%20Setup%201.1.0.exe', '_blank');
};
