import { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import PropTypes from 'prop-types';

const SuccessSync = ({ currentRef }) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  useEffect(() => {
    if (currentRef.current) {
      setDimensions({
        width: currentRef.current.offsetWidth,
        height: currentRef.current.offsetHeight
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="success-step">
        <Confetti
          width={dimensions.width}
          height={dimensions.height}
          numberOfPieces={200}
          colors={['#f44336', '#3f51b5', '#2196f3', '#FFEB3B', '#FFC107']}
          opacity={0.8}
          gravity={0.08}
          style={{ inset: '50px' }}
        />
      </div>
      <h2 className="text-dark text-center">Success!</h2>
      <div className="text-center">
        <span className="text-center">
          {"All your data synced successfully! Now you're ready to process invoices twice as fast as before."}
        </span>
      </div>
    </>
  );
};

SuccessSync.propTypes = {
  currentRef: PropTypes.shape({
    current: PropTypes.shape({
      offsetHeight: PropTypes.number,
      offsetWidth: PropTypes.number
    })
  })
};

export default SuccessSync;
