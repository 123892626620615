import { QueryClient, useQuery } from '@tanstack/react-query';
import { TypedDocumentNode, gql } from '@apollo/client';

import { graphqlClient } from '@global-apis/config';

interface User {
  id: number;
  username: string;
  first_name: string;
  last_name: string;
  full_name: string;
  email: string;
  groups: number[];
  is_active: boolean;
}

interface UserData {
  users: {
    totalCount: number;
    results: User[];
  };
}

interface ActiveUserDataVariables {
  filters: {
    is_active: boolean;
  };
}

const GET_USERS: TypedDocumentNode<UserData> = gql`
  query GetUsersContextUsers {
    users {
      results {
        id
        username
        first_name
        last_name
        full_name
        groups
        email
        is_active
      }
    }
  }
`;

const GET_ACTIVE_USERS: TypedDocumentNode<UserData, ActiveUserDataVariables> = gql`
  query GetActiveUsersContextUsers($filters: AuthUsersFilters) {
    users(filters: $filters) {
      results {
        id
        username
        first_name
        last_name
        full_name
        groups
        email
        is_active
      }
    }
  }
`;

export const useActiveUserList = (options = {}) =>
  useQuery<User[], Error>({
    queryKey: ['users-active'],
    queryFn: async () => {
      const { data } = await graphqlClient.query({
        query: GET_ACTIVE_USERS,
        fetchPolicy: 'network-only',
        variables: { filters: { is_active: true } }
      });

      return data.users.results;
    },
    ...options
  });

export const useUserList = (options = {}) =>
  useQuery<User[], Error>({
    queryKey: ['users'],
    queryFn: async () => {
      const { data } = await graphqlClient.query({ query: GET_USERS, fetchPolicy: 'network-only' });

      return data.users.results;
    },
    ...options
  });

// prettier-ignore
export const invalidateActiveUserList = (queryClient: QueryClient) => queryClient.invalidateQueries({ queryKey: ['users-active'] });

export const invalidateUserList = (queryClient: QueryClient) => queryClient.invalidateQueries({ queryKey: ['users'] });
