import Divider from '@mui/material/Divider';

import { QUICKBOOKS, QUICKBOOKS_DESKTOP } from '@global-utils/defaultValues';
import quickBooksIcon from '@global-assets/images/erp/quickBooks.png';
import ERPRow from '@global-components/SyncWizardModal/components/ERPRow';
import { ERPs } from '@global-interfaces/enums';

const ChooseQuickBooksType = ({ handleSelectERP }: { handleSelectERP: (erp: ERPs) => void }) => {
  return (
    <>
      <ERPRow
        erp={{
          erp: ERPs.QB,
          name: QUICKBOOKS,
          text: 'The QuickBooks version you use is online.',
          icon: quickBooksIcon
        }}
        handleSelectERP={handleSelectERP}
      />
      <Divider />
      <ERPRow
        erp={{
          erp: ERPs.QBD,
          name: QUICKBOOKS_DESKTOP,
          text: 'The QuickBooks version you have liveson a desktop.',
          icon: quickBooksIcon
        }}
        handleSelectERP={handleSelectERP}
      />
    </>
  );
};

export default ChooseQuickBooksType;
