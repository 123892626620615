import { useMemo } from 'react';
import { Typography, Box, styled, Unstable_Grid2 as Grid } from '@mui/material';
import PropTypes from 'prop-types';

import { useAuth } from '@global-contexts/auth';

import './styles.scss';

const LabelKey = ({ description, isMeta, isOption, isLarge, ...rest }) => {
  const { userNavigator } = useAuth();

  const content = useMemo(() => {
    if (isMeta) {
      if (userNavigator?.isMacOs) {
        return '⌘';
      }

      return 'CTRL';
    }

    if (isOption) {
      if (userNavigator?.isMacOs) {
        return 'Option';
      }

      return 'Alt';
    }

    return description;
  }, [description, isMeta, isOption, userNavigator]);

  const KeyWrapper = styled(Box)(({ theme }) => ({
    '&': {
      backgroundColor: theme.palette.grey[220],
      border: `1px solid ${theme.palette.grey[200]}`,
      borderRadius: '4px',
      boxSizing: 'border-box',
      color: theme.palette.grey[700],
      display: 'inline-flex',
      flexShrink: 0,
      ...(isLarge && { fontSize: '24px' }),
      height: isLarge ? '36px' : '20px',
      justifyContent: 'center',
      minWidth: isLarge ? '36px' : '20px',
      fontWeight: 500,
      padding: '0 4px',
      fontFamily: isMeta ? '-apple-system' : 'Monaco'
    }
  }));

  return <KeyWrapper {...rest}>{content}</KeyWrapper>;
};

LabelKey.propTypes = {
  description: PropTypes.string,
  isMeta: PropTypes.bool,
  isOption: PropTypes.bool,
  isLarge: PropTypes.bool
};

const Keys = ({ children, isLarge, ...rest }) => {
  const KeysWrapper = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isLarge'
  })(({ isLarge: G }) => ({
    '&': {
      display: 'inline-flex',
      minWidth: G ? '36px' : '20px',
      gap: G ? '6px' : '4px'
    }
  }));

  return (
    <KeysWrapper component="span" isLarge={isLarge} {...rest}>
      {children}
    </KeysWrapper>
  );
};

Keys.propTypes = {
  isLarge: PropTypes.bool,
  children: PropTypes.node.isRequired
};

const Row = ({ description, children, ...rest }) => {
  const RowWrapper = styled(Box)(({ theme }) => ({
    '&': {
      alignItems: 'center',
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
      display: 'flex',
      justifyContent: 'space-between',
      padding: '4px 0'
    }
  }));

  return (
    <RowWrapper {...rest}>
      <Typography>{description}</Typography>
      {children}
    </RowWrapper>
  );
};

Row.propTypes = {
  description: PropTypes.string,
  children: PropTypes.node.isRequired
};

const ColumnLabel = ({ children, ...rest }) => {
  const ColumnLabelWrapper = styled(Typography)(({ theme }) => ({
    '&': {
      color: theme.palette.grey[950],
      fontWeight: 'bold'
    }
  }));

  return (
    <ColumnLabelWrapper variant="body2" {...rest}>
      {children}
    </ColumnLabelWrapper>
  );
};

ColumnLabel.propTypes = {
  children: PropTypes.node.isRequired
};

const Column = ({ children, ...rest }) => {
  return (
    <Grid display="flex" flexDirection="column" {...rest}>
      <Box display="inline-block" width="380px">
        {children}
      </Box>
    </Grid>
  );
};

Column.propTypes = {
  children: PropTypes.node.isRequired
};

const KeyboardShortcuts = ({ onClose }) => {
  return (
    <Box tabIndex="0" className="keyboard-shortcuts__modal" data-testid="keyboard-shortcuts">
      <Box>
        <Box
          component="header"
          sx={{
            display: 'flex',
            flex: '1 0 auto',
            justifyContent: 'space-between',
            marginBottom: '24px',
            alignItems: 'center'
          }}>
          <Box
            data-testid="keyboard-shortcuts-title"
            sx={{ display: 'flex', flexDirect: 'column', gap: '4px', alignItems: 'center' }}>
            <Typography variant="h4">Keyboard Shortcuts</Typography>
            <Box component="span" data-testid="keyboard-shortcuts-title-keys">
              <Keys isLarge>
                <LabelKey isLarge isMeta />
                <LabelKey isLarge description="/" />
              </Keys>
            </Box>
          </Box>
          <Box
            className="keyboard-shortcuts__modal__close"
            role="button"
            tabIndex="0"
            aria-label="Close this dialog"
            onClick={onClose}>
            <svg className="Icon XIcon" viewBox="0 0 32 32" aria-hidden="true" focusable="false">
              <path d="M18.1,16L27,7.1c0.6-0.6,0.6-1.5,0-2.1s-1.5-0.6-2.1,0L16,13.9l-8.9-9C6.5,4.3,5.6,4.3,5,4.9S4.4,6.4,5,7l8.9,8.9L5,24.8c-0.6,0.6-0.6,1.5,0,2.1c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4l8.9-8.9l8.9,8.9c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4c0.6-0.6,0.6-1.5,0-2.1L18.1,16z"></path>
            </svg>
          </Box>
        </Box>

        <Grid
          component="main"
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, md: 2 }}
          justifyContent="center"
          gap="12px">
          <Column data-testid="keyboard-shortcuts-content-application">
            <ColumnLabel>Application</ColumnLabel>
            <Row description="Search" data-testid="keyboard-shortcuts-content-application-search">
              <Keys data-testid="keyboard-shortcuts-content-application-search-keys">
                <LabelKey isOption />
                <LabelKey description="S" />
              </Keys>
            </Row>
            <Row description="Go to Inbox" data-testid="keyboard-shortcuts-content-application-inbox">
              <Keys data-testid="keyboard-shortcuts-content-application-inbox-keys">
                <LabelKey isOption />
                <LabelKey description="I" />
              </Keys>
            </Row>
            <Row description="Go to Approvals" data-testid="keyboard-shortcuts-content-application-approvals">
              <Keys data-testid="keyboard-shortcuts-content-application-approvals-keys">
                <LabelKey isOption />
                <LabelKey description="P" />
              </Keys>
            </Row>
            <Row
              description="Go to Accounts Payable"
              data-testid="keyboard-shortcuts-content-application-accounts-payable">
              <Keys data-testid="keyboard-shortcuts-content-application-accounts-payable-keys">
                <LabelKey isOption />
                <LabelKey description="A" />
              </Keys>
            </Row>
            {/* <Row description="Go to Projects">
              <Keys>
                <LabelKey isOption />
                <LabelKey description="O" />
              </Keys>
            </Row> */}
          </Column>
          <Column data-testid="keyboard-shortcuts-content-invoice">
            <ColumnLabel>Invoice</ColumnLabel>
            <Row description="Edit Invoice Details" data-testid="keyboard-shortcuts-content-invoice-edit">
              <Keys data-testid="keyboard-shortcuts-content-invoice-edit-keys">
                <LabelKey isMeta />
                <LabelKey description="E" />
              </Keys>
            </Row>
            <Row description="Add Approvers" data-testid="keyboard-shortcuts-content-invoice-add">
              <Keys data-testid="keyboard-shortcuts-content-invoice-add-keys">
                <LabelKey isMeta />
                <LabelKey description="G" />
              </Keys>
            </Row>
            <Row description="Submit / Approve" data-testid="keyboard-shortcuts-content-invoice-submit">
              <Keys data-testid="keyboard-shortcuts-content-invoice-submit-keys">
                <LabelKey isMeta />
                <LabelKey description="↵ Return" />
              </Keys>
            </Row>
            <Row description="Dispute" data-testid="keyboard-shortcuts-content-invoice-dispute">
              <Keys data-testid="keyboard-shortcuts-content-invoice-dispute-keys">
                <LabelKey isMeta />
                <LabelKey description="D" />
              </Keys>
            </Row>
            <Row description="Previous Invoice" data-testid="keyboard-shortcuts-content-invoice-previous">
              <Keys data-testid="keyboard-shortcuts-content-invoice-previous-keys">
                <LabelKey isMeta />
                <LabelKey description="←" />
              </Keys>
            </Row>
            <Row description="Next Invoice" data-testid="keyboard-shortcuts-content-invoice-next">
              <Keys data-testid="keyboard-shortcuts-content-invoice-next-keys">
                <LabelKey isMeta />
                <LabelKey description="→" />
              </Keys>
            </Row>
            <Row description="Delete Invoice" data-testid="keyboard-shortcuts-content-invoice-delete">
              <Keys data-testid="keyboard-shortcuts-content-invoice-delete-keys">
                <LabelKey isMeta />
                <LabelKey description="Del" />
              </Keys>
            </Row>

            <ColumnLabel marginTop="10px">Line Item</ColumnLabel>

            <Row description="Add" data-testid="keyboard-shortcuts-content-line-item-add">
              <Keys data-testid="keyboard-shortcuts-content-line-item-add-keys">
                <LabelKey isMeta />
                <LabelKey description="J" />
              </Keys>
            </Row>
          </Column>
        </Grid>
      </Box>
    </Box>
  );
};

KeyboardShortcuts.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default KeyboardShortcuts;
