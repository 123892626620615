import { useQuery } from '@tanstack/react-query';
import { TypedDocumentNode, gql } from '@apollo/client';

import { graphqlClient } from '@global-apis/config';

interface Tag {
  id: number;
  color: string;
  name: string;
  default: boolean;
}

interface TagData {
  tags: Tag[];
}

const GET_TAGS: TypedDocumentNode<TagData> = gql`
  query GetTagsContextTag {
    tags {
      id
      color
      name
      default
    }
  }
`;

export const useTagList = (options = {}) =>
  useQuery<Tag[], Error>({
    queryKey: ['tags'],
    queryFn: async () => {
      const { data } = await graphqlClient.query({ query: GET_TAGS, fetchPolicy: 'network-only' });
      return data.tags;
    },
    ...options
  });
