import { FC, ReactNode, createContext, useContext, useState } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';

interface LoadingContextType {
  isShowingBackdrop: boolean;

  showBackdrop: () => void;
  hideBackdrop: () => void;
}

export const LoadingContext = createContext<LoadingContextType>({} as LoadingContextType);

const LoadingProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [isShowing, setIsShowing] = useState(false);

  const showBackdrop = () => {
    setIsShowing(true);
  };

  const hideBackdrop = () => {
    setIsShowing(false);
  };

  return (
    <LoadingContext.Provider
      value={{
        isShowingBackdrop: isShowing,

        showBackdrop,
        hideBackdrop
      }}>
      {isShowing && (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {children}
    </LoadingContext.Provider>
  );
};

LoadingProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const useLoading = () => useContext(LoadingContext);

export default LoadingProvider;
