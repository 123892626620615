import { useMemo } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

interface QueryParams {
  [key: string]: string | null;
}

const useRoute = (...rest: string[]) => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const queryParams = useMemo(() => {
    const foundSearchParams = new URLSearchParams(search);
    const foundQueryParams: QueryParams = rest.reduce(
      (prev, curr) => ({
        ...prev,
        [curr]: foundSearchParams.get(curr)
      }),
      {}
    );

    return foundQueryParams;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  /**
   * @deprecated This function will be removed.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setSearch = (paramName: any, currentValue: any, defaultValue?: any) => {
    const searchParams = new URLSearchParams(search);

    if (JSON.stringify(currentValue) === JSON.stringify(defaultValue)) {
      searchParams.delete(paramName);
    } else {
      searchParams.set(paramName, currentValue);
    }

    navigate({ search: decodeURIComponent(searchParams.toString()) });
  };

  // params is an array of objects. e.g. [{ name: 'param1', value: 'value1', defaultValue: 'abc' }, { name: 'param2', value: 'value2' }]
  const setParams = (params: { name: string; value: string | boolean; defaultValue?: string | boolean }[]) => {
    const searchParams = new URLSearchParams(search);

    params.forEach((param) => {
      if (JSON.stringify(param.value) === JSON.stringify(param.defaultValue)) {
        searchParams.delete(param.name);
      } else {
        if (param.value === '') {
          searchParams.delete(param.name);
        } else {
          searchParams.set(param.name, param.value.toString());
        }
      }
    });

    navigate({ search: decodeURIComponent(searchParams.toString()) });
  };

  // it can set multiple params at once. e.g. removeParams('param1', 'param2', 'param3') or removeParams('param1')
  const removeParams = (...paramNames: string[]) => {
    const searchParams = new URLSearchParams(search);
    paramNames.forEach((param) => searchParams.delete(param));
    navigate({ search: decodeURIComponent(searchParams.toString()) });
  };

  return {
    queryParams,
    setParams,
    removeParams,
    setSearch //DEPRECATED - use setParams instead
  };
};

export default useRoute;
