import qs from 'qs';
import api, { PaginatedResponse } from './config';
import User, { ProjectUser } from '@global-interfaces/User';
import { AxiosResponse } from 'axios';

interface SwitchCompanyProps extends User {
  access: string;
  refresh: string;
}

const userAPI = {
  list: (page?: number, params = {}) =>
    api.get<PaginatedResponse<User>>('users/', {
      params: { ...params, page: page ? page : 1 },
      paramsSerializer: { serialize: (params) => qs.stringify(params, { arrayFormat: 'repeat' }) }
    }),
  me: async () => api.get<User>(`users/me/`),
  fetch: async (id) => api.get(`users/${id}/`),
  update: async (id: number, data: Partial<User>) => api.patch(`users/${id}/`, data),
  createUser: async (data: Partial<User>): Promise<AxiosResponse<User, Error>> => api.post<User>('/users/', data),
  createProfile: async (data: { user: number; company_id: number }) => api.post('/profiles/', data),
  setPassword: async (id, data) => api.post(`users/${id}/set_password/`, data),
  updateProfile: async (id, data) => api.patch(`profiles/${id}/`, data),
  switchCompany: async (company_id: number) => api.post<SwitchCompanyProps>('profiles/switch-company/', { company_id }),

  projectUser: {
    list: async () => api.get('project-user/'),
    fetchByProject: async (projectId: number) =>
      api.get<PaginatedResponse<ProjectUser>>('project-user/', {
        params: { project: projectId },
        paramsSerializer: { serialize: (params) => qs.stringify(params, { arrayFormat: 'repeat' }) }
      }),
    update: async (data: {
      user: number;
      user_email?: string;
      user_password?: string;
      first_name?: string;
      last_name?: string;
      project: number;
      project_name: string;
      billable_rate: number;
    }) => api.patch('project-user/update/', data)
  }
};

export default userAPI;
