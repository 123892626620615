import { useMemo } from 'react';
import { Tooltip } from 'react-tooltip';
import PropTypes from 'prop-types';

import { useAuth } from '@global-contexts/auth';

import styles from '@global-assets/scss/config/_custom-variables.module.scss';
const { zIndexToast } = styles;

import './styles.scss';

const Shortcut = ({ targetElement, place = 'bottom', title, hasNoPressText = false, children }) => {
  return (
    <Tooltip
      id={targetElement}
      className="shortcut"
      style={{ backgroundColor: '#6c757d', zIndex: zIndexToast }}
      place={place}>
      <div className="shortcut__wrapper">
        <div className="shortcut__wrapper__title">{title}</div>
        <div className="shortcut__wrapper__content">
          {!hasNoPressText ? <div className="shortcut__wrapper__content__describe">Press</div> : null}
          <div className="shortcut__wrapper__content__keys">{children}</div>
        </div>
      </div>
    </Tooltip>
  );
};

export const Item = ({ description, isMeta = false, isOption = false }) => {
  const { userNavigator } = useAuth();
  const content = useMemo(() => {
    if (isMeta) {
      if (userNavigator?.isMacOs) {
        return '⌘';
      }

      return 'CTRL';
    }

    if (isOption) {
      if (userNavigator?.isMacOs) {
        return 'Option';
      }

      return 'Alt';
    }

    return description;
  }, [description, isMeta, isOption, userNavigator]);

  return <div className={`shortcut__item ${isMeta ? 'shortcut__item--is-mac-symbol' : ''}`}>{content}</div>;
};

Shortcut.propTypes = {
  targetElement: PropTypes.string.isRequired,
  place: PropTypes.oneOf([
    'top',
    'top-start',
    'top-end',
    'right',
    'right-start',
    'right-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'left',
    'left-start',
    'left-end'
  ]),
  title: PropTypes.string,
  hasNoPressText: PropTypes.bool,
  children: PropTypes.node.isRequired
};

Item.propTypes = {
  description: PropTypes.string,
  isMeta: PropTypes.bool,
  isOption: PropTypes.bool
};

export default Shortcut;
