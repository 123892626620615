import logo from '@global-assets/images/logo/inbuild_logo_lockup_01_blue.svg';
import chromeIcon from '@global-assets/images/browser/chrome.svg';
import safariIcon from '@global-assets/images/browser/safari.svg';
import firefoxIcon from '@global-assets/images/browser/firefox.svg';
import edgeIcon from '@global-assets/images/browser/edge.svg';

import './styles.scss';

const UnsupportedBrowser = () => {
  return (
    <div className="unsupported-browser">
      <div className="unsupported-browser__wrapper-1">
        <div className="unsupported-browser__wrapper-1__wrapper-2">
          <div className="card shadow unsupported-browser__wrapper-1__wrapper-2__wrapper-3">
            <div className="card-header unsupported-browser__wrapper-1__wrapper-2__wrapper-3__header">
              <div>
                <img src={logo} alt="logo" width="120px" className="new-version-logo" />
              </div>
            </div>
            <div className="card-body unsupported-browser__wrapper-1__wrapper-2__wrapper-3__content">
              <h2 className="unsupported-browser__wrapper-1__wrapper-2__wrapper-3__content___title">
                Oops, your browser isn&apos;t supported
              </h2>
              <p className="unsupported-browser__wrapper-1__wrapper-2__wrapper-3__content__subtitle">
                To use inBuild you will need to update your browser version or upgrade to one of the following browsers:
              </p>
              <div className="unsupported-browser__wrapper-1__wrapper-2__wrapper-3__content__browsers" cellPadding="4">
                <a
                  className="font-12 text-primary unsupported-browser__wrapper-1__wrapper-2__wrapper-3__content__browsers__browser"
                  href="https://www.google.com/chrome"
                  target="_blank"
                  rel="noopener noreferrer">
                  <div className="">
                    <img src={chromeIcon} width="50px" alt="chrome logo" />
                  </div>
                  Chrome ≥ 90
                </a>
                <a
                  className="font-12 text-primary unsupported-browser__wrapper-1__wrapper-2__wrapper-3__content__browsers__browser"
                  href="https://www.apple.com/safari/"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img src={safariIcon} width="50px" alt="safari logo" />
                  Safari ≥ 14
                </a>
                <a
                  className="font-12 text-primary unsupported-browser__wrapper-1__wrapper-2__wrapper-3__content__browsers__browser"
                  href="https://www.mozilla.com/firefox/"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img src={firefoxIcon} width="50px" alt="firefox logo" />
                  Firefox ≥ 63
                </a>
                <a
                  className="font-12 text-primary unsupported-browser__wrapper-1__wrapper-2__wrapper-3__content__browsers__browser"
                  href="https://www.microsoft.com/en-us/edge"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img src={edgeIcon} width="50px" alt="edge logo" />
                  Edge ≥ 90
                </a>
              </div>

              <p className="unsupported-browser__wrapper-1__wrapper-2__wrapper-3__content__support">
                <span>Contact our</span>
                <a href="mailto:support@inbuild.ai?subject=Unsupported Browser&body=I can't access the app because my browser seems to be out of date">
                  Team
                </a>
                <span>about browser compatibility.</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnsupportedBrowser;
