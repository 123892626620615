import { useRef, useState } from 'react';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import Modal, { ModalBody, ModalFooter, ModalHeader } from '@global-components/ModalMui';
import ChooseQuickBooksType from '@global-components/SyncWizardModal/components/ChooseQuickBooksType';
import ERPStep from '@global-components/SyncWizardModal/components/ERPStep';
import SyncStepper from '@global-components/SyncWizardModal/components/SyncStepper';
import SuccessSync from '@global-components/SyncWizardModal/components/SuccessSync';
import companyAPI from '@global-apis/company';
import { useAuth } from '@global-contexts/auth';
import { ERPs } from '@global-interfaces/enums';
import { IntegrationSetupStatus } from '@global-interfaces/Company';

const defaultIntegrationsSetupStatus: IntegrationSetupStatus[] = [
  { erp: ERPs.QB, status: 'ready' },
  { erp: ERPs.QBD, status: 'ready' },
  { erp: ERPs.PROCORE, status: 'ready' },
  { erp: ERPs.SAGE, status: 'ready' }
];

const SyncWizardModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  const currentRef = useRef(null);
  const [step, setStep] = useState('erps');
  const [selectedErp, setSelectedERP] = useState<ERPs>(ERPs.NONE);
  const [isSyncing, setIsSyncing] = useState<boolean>(false);
  const { company, fetchCompanyData } = useAuth();
  const flags = company?.company_flags;

  const resetSteps = () => {
    setSelectedERP(ERPs.NONE);
    setStep('erps');
    onClose();
  };

  const integrationsSetupStatus = flags?.integrations_setup ?? defaultIntegrationsSetupStatus;

  const handleDontAskAgain = async () => {
    await companyAPI
      .update(company.id!, {
        company_flags: {
          integration_setup_refusal: true
        }
      })
      .then(() => {
        fetchCompanyData();
        onClose();
      })
      .catch((err) => {
        if (err) {
          onClose();
        }
      });
  };

  const handleSelectERP = (erp: ERPs) => {
    if (step !== 'quickbooks' && erp === ERPs.QB) {
      setStep('quickbooks');
    } else {
      setStep('sync');
    }
    setSelectedERP(erp);
  };

  const renderTitle = () => {
    switch (step) {
      case 'erps':
        return 'Sync your company data';
      case 'quickbooks':
        return 'Choose QuickBooks Account';
      case 'sync':
        return 'Sync your company data';
      case 'success':
        return 'Your company data is synced';
    }
  };

  const renderStep = () => {
    switch (step) {
      case 'erps':
        return <ERPStep handleSelectERP={handleSelectERP} integrationsSetupStatus={integrationsSetupStatus} />;
      case 'quickbooks':
        return <ChooseQuickBooksType handleSelectERP={handleSelectERP} />;
      case 'sync':
        return <SyncStepper erp={selectedErp} setFinalStep={() => setStep('success')} setIsSyncing={setIsSyncing} />;
      case 'success':
        return <SuccessSync currentRef={currentRef} />;
    }
  };

  const handleBack = () => {
    if (selectedErp === ERPs.QB || selectedErp === ERPs.QBD) {
      setStep('quickbooks');
    } else {
      setStep('erps');
    }
    setSelectedERP(ERPs.NONE);
  };

  return (
    <Modal isOpen={isOpen} onClose={resetSteps}>
      <ModalHeader onClose={resetSteps}>{renderTitle()}</ModalHeader>
      <ModalBody>
        <div ref={currentRef}>{renderStep()}</div>
      </ModalBody>
      <ModalFooter>
        <Stack direction="row" alignItems="space-between">
          {!flags?.integration_setup_refusal && step === 'erps' ? (
            <Button onClick={handleDontAskAgain}>{"Don't ask again"}</Button>
          ) : step !== 'success' && step !== 'erps' ? (
            <Button onClick={handleBack} disabled={isSyncing}>
              Go back
            </Button>
          ) : null}
          <Button variant="contained" onClick={resetSteps} disabled={isSyncing}>
            CLOSE
          </Button>
        </Stack>
      </ModalFooter>
    </Modal>
  );
};

export default SyncWizardModal;
