import { Tooltip } from 'react-tooltip';
import { Link } from 'react-router-dom';

import menuOpenIcon from '@global-assets/images/menu-open.svg';
import menuCloseIcon from '@global-assets/images/menu-close.svg';
import logoOpen from '@global-assets/images/logo/inbuild_logo_lockup_01_blue.svg';

import { useAuth } from '@global-contexts/auth';

import AppMenu from '@global-components/AppMenu';

import styles from '@global-assets/scss/config/_custom-variables.module.scss';
const { zIndexToast } = styles;

import './styles.scss';

const LeftSidebar = ({ isCondensed, onIsCondensed }: { isCondensed: boolean; onIsCondensed: () => void }) => {
  const { company } = useAuth();

  return (
    <aside className={`left-sidebar ${isCondensed ? 'left-sidebar--condensed' : ''}`} data-testid="leftsidebar-wrapper">
      <div className={`left-sidebar__logo-wrapper ${isCondensed ? 'left-sidebar__logo-wrapper--condensed' : ''}`}>
        <Link
          to="/"
          className={`left-sidebar__logo ${isCondensed ? 'left-sidebar__logo--condensed' : ''}`}
          style={{ display: 'flex', alignItems: 'center' }}>
          {company.partner_logo ? (
            <img src={company.partner_logo} alt="partner logo" width="100%" />
          ) : (
            <img src={logoOpen} alt="logo" width="100%" />
          )}
        </Link>

        <button
          className={`btn left-sidebar__btn-menu ${isCondensed ? 'left-sidebar__btn-menu--condensed' : ''}`}
          onClick={onIsCondensed}
          data-tooltip-id="left-sidebar-button">
          <img alt="collapse menu button" src={isCondensed ? menuCloseIcon : menuOpenIcon} height="24" width="24" />
        </button>
        <Tooltip id="left-sidebar-button" variant="info" delayShow={400} place="bottom" style={{ zIndex: zIndexToast }}>
          <span>{isCondensed ? 'Show sidebar' : 'Hide sidebar'}</span>
        </Tooltip>
      </div>

      <AppMenu isCondensed={isCondensed} />
    </aside>
  );
};

export default LeftSidebar;
