import { Stack, Divider } from '@mui/material';
import { Settings as SettingsIcon } from '@mui/icons-material';

import quickBooksIcon from '@global-assets/images/erp/quickBooks.png';
import procoreIcon from '@global-assets/images/erp/procore.png';
import sageIcon from '@global-assets/images/erp/sage.png';
import ERPRow from '@global-components/SyncWizardModal/components/ERPRow';
import { PROCORE, SAGE, QB } from '@global-utils/defaultValues';
import { IntegrationSetupStatus } from '@global-interfaces/Company';
import { ERPs } from '@global-interfaces/enums';

import './styles.scss';

const ERPStep = ({
  handleSelectERP,
  integrationsSetupStatus
}: {
  handleSelectERP: (erp: ERPs) => void;
  integrationsSetupStatus: IntegrationSetupStatus[];
}) => {
  //manual option implementation is on hold
  const manualAvailable = false;
  const erps = [
    {
      erp: ERPs.QB,
      name: QB,
      icon: quickBooksIcon,
      text: 'Connect to your QuickBooks account and sync your data.'
    },
    {
      erp: ERPs.PROCORE,
      name: PROCORE,
      icon: procoreIcon,
      text: 'Connect to your Procore account and sync your data.'
    },
    { erp: ERPs.SAGE, name: SAGE, icon: sageIcon, text: 'Connect to your Sage database and sync your data.' }
  ];

  const getSyncStatus = (erp: string) => {
    if (erp === QB) {
      return (
        integrationsSetupStatus?.find((i) => i.erp === QB)?.status === 'synced' &&
        integrationsSetupStatus?.find((i) => i.erp === 'QuickBooksDesktop')?.status === 'synced'
      );
    }
    return integrationsSetupStatus?.some((i) => i.erp === erp && i?.status === 'synced');
  };

  return (
    <>
      <Stack divider={<Divider />}>
        {erps.map((erp) => {
          return (
            <span key={erp.erp}>
              <ERPRow erp={erp} handleSelectERP={handleSelectERP} erpIsSynced={getSyncStatus(erp.erp)} />
            </span>
          );
        })}
      </Stack>
      {manualAvailable && (
        <Stack direction="column">
          <Divider>OR</Divider>
          <Stack direction="row" alignItems="center" justifyContent="space-between" gap={2} sx={{ p: 4 }}>
            <Stack direction="column" alignItems="center" sx={{ width: '100px' }}>
              <SettingsIcon sx={{ fontSize: 24, color: '#36b2d4' }} />
              <span>Manual Import</span>
            </Stack>
            <div>Manually upload your vendors, projects and cost codes.</div>
            <div>
              <button className="btn btn-primary btn-blue" onClick={() => handleSelectERP(ERPs.NONE)}>
                Begin
              </button>
            </div>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default ERPStep;
