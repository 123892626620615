import { createTheme, responsiveFontSizes, BreakpointOverrides, PaletteOptions, Shadows } from '@mui/material/styles';

import scssVariables from '@global-assets/scss/config/_custom-variables.module.scss';

const { palette } = createTheme();
const { augmentColor } = palette;

declare module '@mui/material/styles' {
  interface Breakpoints {
    mobile: number;
    tablet: number;
    laptop: number;
    desktop: number;
    wide: number;
  }
  interface Palette {
    grey250?: PaletteColor;
    grey400: PaletteColor;
    grey500: PaletteColor;
    grey700: PaletteColor;
    neutral: PaletteColor;
    cyan: PaletteColor;
    black: PaletteColor;
    error: PaletteColor;
  }
  interface PaletteColor {
    grey250?: string;
    grey400?: string;
    grey500?: string;
    grey700?: string;
    neutral?: PaletteColor;
  }

  interface SimplePaletteColorOptions {
    grey250?: string;
    grey400?: string;
    grey500?: string;
    grey700?: string;
    neutral?: string;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    grey250: true;
    grey400: true;
    grey500: true;
    grey700: true;
  }
}

const createColor = (mainColor: string, darkColor?: string) => {
  const colorDefinition = { main: mainColor, dark: darkColor };

  return augmentColor({ color: colorDefinition });
};

const grey = {
  50: '#fafafa',
  100: '#f1f3fa',
  200: '#eef2f7',
  220: '#f9f8f8',
  250: '#dee2e6',
  300: '#c1c1c1',
  400: '#ced4da',
  500: '#adb5bd',
  600: '#98a6ad',
  700: '#6c757d',
  800: '#343a40',
  900: '#313a46',
  950: '#1e1f21',
  A100: '#fafafa',
  A200: '#eef2f7',
  A400: '#ced4da',
  A700: '#6c757d'
};

let appTheme = createTheme({
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 640,
      laptop: 1024,
      desktop: 1200,
      wide: 1536
      // above are deprecated. Use below instead
      // xs: 0,
      // sm: 600,
      // md: 900,
      // lg: 1200,
      // xl: 1536
    }
  } as BreakpointOverrides,
  palette: {
    background: { default: '#fafbfe' },
    primary: { ...createColor('#59afd4'), contrastText: '#fff' },
    secondary: createColor('#313a46'),
    grey250: createColor(grey[250], grey[400]),
    grey400: createColor(grey[400]),
    grey500: createColor(grey[500]),
    grey700: createColor(grey[700]),
    grey,
    success: { ...createColor('#0acf97', '#00a17a'), contrastText: '#fff' },
    neutral: { ...createColor('#c8e5f1'), contrastText: '#fff' },
    cyan: createColor('#36b2d4'),
    black: createColor('#000'),
    error: createColor('#fa5c7c')
  } as PaletteOptions,
  typography: {
    // Tell MUI what the font-size on the html element is
    // After migrate all elements to use the MUI,
    // define `html {font-size: 87.5%; // 1rem = 16px. 87.5% of 16px = 14px }` on _general.scss file and `htmlFontSize: 14,` below
    // also, remove all overrides of `fontSize` on bellow
    htmlFontSize: 16,
    allVariants: {
      color: grey[950]
    },
    body1: {
      fontSize: '0.875rem'
    },
    h6: {
      fontSize: '18px',
      fontWeight: 700,
      color: grey[700]
    },
    fontFamily: 'Roboto,Poppins,Arial'
  },
  shadows: [
    'none',
    '0 1px 3px 0 rgba(154, 161, 171, 0.2), 0 1px 2px 0 rgba(154, 161, 171, 0.14), 0 2px 4px 0 rgba(154, 161, 171, 0.12)',
    '0 1px 5px 0 rgba(154, 161, 171, 0.2), 0 2px 4px 0 rgba(154, 161, 171, 0.14), 0 3px 6px 0 rgba(154, 161, 171, 0.12)',
    '0 3px 5px 0 rgba(154, 161, 171, 0.2), 0 3px 4px 0 rgba(154, 161, 171, 0.14), 0 4px 7px 0 rgba(154, 161, 171, 0.12)',
    '0 2px 8px 0 rgba(154, 161, 171, 0.2), 0 5px 10px 0 rgba(154, 161, 171, 0.14), 0 6px 12px 0 rgba(154, 161, 171, 0.12)',
    '0 2px 8px 0 rgba(154, 161, 171, 0.2), 0 5px 10px 0 rgba(154, 161, 171, 0.14), 0 6px 12px 0 rgba(154, 161, 171, 0.12)',
    '0 2px 8px 0 rgba(154, 161, 171, 0.2), 0 5px 10px 0 rgba(154, 161, 171, 0.14), 0 6px 12px 0 rgba(154, 161, 171, 0.12)',
    '0 2px 8px 0 rgba(154, 161, 171, 0.2), 0 5px 10px 0 rgba(154, 161, 171, 0.14), 0 6px 12px 0 rgba(154, 161, 171, 0.12)',
    '0 2px 8px 0 rgba(154, 161, 171, 0.2), 0 5px 10px 0 rgba(154, 161, 171, 0.14), 0 6px 12px 0 rgba(154, 161, 171, 0.12)',
    '0 2px 8px 0 rgba(154, 161, 171, 0.2), 0 5px 10px 0 rgba(154, 161, 171, 0.14), 0 6px 12px 0 rgba(154, 161, 171, 0.12)',
    ...Array(15).fill('none')
  ] as Shadows,
  components: {
    // reset css
    MuiCssBaseline: {
      styleOverrides: {
        fieldset: {
          border: 0,
          margin: 0,
          padding: 0
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderRadius: 5,
          textTransform: 'none',
          ...(ownerState.variant === 'contained' &&
            (ownerState.color as string) === 'neutral' && {
              color: grey[700],
              '&:hover': { color: grey[500] }
            })
        })
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '1rem'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          color: grey[700]
        }
      }
    },
    MuiPickersPopper: {
      styleOverrides: {
        root: {
          zIndex: scssVariables.zIndexModal
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        // @ts-expect-error Property 'color' does not exist on type 'Partial<CheckboxProps>'
        '&.Mui-checked': {
          color: '#59afd4'
        }
      }
    }
  },
  zIndex: {
    modal: Number(scssVariables.zIndexModal)
  }
});

appTheme = responsiveFontSizes(appTheme);

export { appTheme };
