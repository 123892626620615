import { FC, ReactNode, Suspense, useState, useEffect, useMemo, useCallback } from 'react';
import { Box, AppBar, Stack, CircularProgress, Grid, Typography, Snackbar, Alert, Button } from '@mui/material';

import { useNavigate, useLocation } from 'react-router-dom';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { useHotkeys } from 'react-hotkeys-hook';
import { NorthWest as NorthWestIcon } from '@mui/icons-material';

import { getBrowser } from '@global-utils/Helpers';

import { useAuth } from '@global-contexts/auth';

import LeftSidebar from '@global-components/LeftSidebar';
import Topbar from '@global-components/Topbar';
import KeyboardShortcuts from '@global-components/KeyboardShortcuts';

import styles from '@global-assets/scss/config/_custom-variables.module.scss';
const { zIndexTopbar, topbarHeight, leftSidebarWidth, leftSidebarWidthCollapsed } = styles;

const matchesPath = (currentPath: string, paths: string[]) => {
  return paths.some((pattern) => {
    const regexPattern = pattern.replace(/:\w+/g, '[^/]+');
    const regex = new RegExp(`^${regexPattern}$`);
    return currentPath.match(regex);
  });
};

const _REMOVE_FULL_PADDING = ['/new-erp/docusign'];
const _REMOVE_Y_PADDING = ['/inbox/new-invoice/:id', '/inbox/new-review/:id'];

const AuthenticatedLayout: FC<{ children: ReactNode }> = ({ children }) => {
  const { userNavigator, isSidebarCondensed, setIsSidebarCondensed } = useAuth();

  const location = useLocation();
  const navigate = useNavigate();

  const removeFullPadding = useMemo(() => matchesPath(location.pathname, _REMOVE_FULL_PADDING), [location]);
  const removeYPadding = useMemo(() => matchesPath(location.pathname, _REMOVE_Y_PADDING), [location]);

  const [isShowingShortcuts, setIsShowingShortcuts] = useState(false);

  const onShowingShortcuts = (newStatus: boolean) => {
    document.body.style.overflow = newStatus ? 'hidden' : '';

    setIsShowingShortcuts(newStatus);
  };

  const handleFullscreen = useFullScreenHandle();

  const [width, setWidth] = useState(window.innerWidth);
  const isSmallScreen = useMemo(() => width <= 1400, [width]);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  const [isEdgedClosed, setIsEdgeClosed] = useState(() => {
    const isEdgeBrowser = getBrowser() === 'Edge';

    if (isEdgeBrowser) {
      const storedValue = localStorage.getItem('__microsoft-edge-closed-by-user');

      return storedValue === 'true';
    }

    return true;
  });

  const onCloseEdgeWarning = () => {
    localStorage.setItem('__microsoft-edge-closed-by-user', 'true');

    setIsEdgeClosed(true);
  };

  const setByStorage = () => {
    const storedValue = localStorage.getItem('__menu-is-condensed');

    if (storedValue != null) {
      const found = storedValue === 'true';
      setIsSidebarCondensed(found);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);

    setByStorage();

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSmallScreen) {
      setIsSidebarCondensed(true);
    } else {
      setByStorage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSmallScreen]);

  const handleIsCondensed = () => {
    const newValue = !isSidebarCondensed;
    localStorage.setItem('__menu-is-condensed', newValue.toString());
    setIsSidebarCondensed(newValue);
  };

  const allowedMetaWhichOs = useCallback(
    ({ ctrl, meta }: { ctrl: boolean | undefined; meta: boolean | undefined }) => {
      if (userNavigator) {
        if (userNavigator.isMacOs) {
          return !ctrl;
        } else {
          return !meta;
        }
      }
      return false;
    },
    [userNavigator]
  );

  useHotkeys('escape', () => onShowingShortcuts(false), {
    scopes: 'global',
    enabled: isShowingShortcuts
  });

  useHotkeys(
    ['meta+/', 'ctrl+/'],
    (keyboardEvent, { ctrl, meta }) => {
      if (!allowedMetaWhichOs({ ctrl, meta })) {
        return;
      }

      if (keyboardEvent.code === 'Slash') {
        onShowingShortcuts(true);
      }
    },
    {
      scopes: 'global',
      preventDefault: true,
      enableOnFormTags: ['input', 'select', 'textarea']
    }
  );

  useHotkeys(
    'alt+i',
    (keyboardEvent) => {
      if (keyboardEvent.code === 'KeyI') {
        navigate('/');
      }
    },
    // it's necessary because the keyup is catches the KeyS by onChange input event
    {
      scopes: 'global',
      preventDefault: true,
      enableOnFormTags: ['input', 'select', 'textarea']
    }
  );

  useHotkeys(
    'alt+p',
    (keyboardEvent) => {
      if (keyboardEvent.code === 'KeyP') {
        navigate('/approvals');
      }
    },
    {
      scopes: 'global',
      preventDefault: true,
      enableOnFormTags: ['input', 'select', 'textarea']
    }
  );

  useHotkeys(
    'alt+a',
    (keyboardEvent) => {
      if (keyboardEvent.code === 'KeyA') {
        navigate('/accounts-payable');
      }
    },
    {
      scopes: 'global',
      preventDefault: true,
      enableOnFormTags: ['input', 'select', 'textarea']
    }
  );

  useHotkeys(
    'alt+o',
    (keyboardEvent) => {
      if (keyboardEvent.code === 'KeyO') {
        navigate('/projects');
      }
    },
    {
      scopes: 'global',
      preventDefault: true,
      enableOnFormTags: ['input', 'select', 'textarea']
    }
  );

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100vw',
        height: '100vh',
        overflow: 'hidden'
      }}
      data-testid="authenticated-wrapper">
      <Snackbar
        open={!isEdgedClosed}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        sx={{ left: 100, top: 1, width: 450, paddingLeft: '20px' }}>
        <Alert
          severity="warning"
          variant="filled"
          icon={<NorthWestIcon fontSize="inherit" />}
          action={
            <Button color="inherit" size="small" onClick={onCloseEdgeWarning}>
              X
            </Button>
          }>
          <Grid container display="flex" direction="column" gap={1}>
            <Typography color="inherit" fontWeight="500">
              Microsoft Edge browser is blocking the invoice viewer.
            </Typography>
            <Typography color="inherit">
              Please click the lock icon in the URL bar, then turn off “Tracking prevention for this site (Balanced)“.
            </Typography>
            <Typography color="inherit">
              This will fix the issue on Microsoft Edge. Alternatively, If you switch to Google Chrome, this problem
              will not persist.
            </Typography>
          </Grid>
        </Alert>
      </Snackbar>

      <AppBar
        position="fixed"
        sx={{
          boxShadow: '0px 0px 35px 0px rgba(154, 161, 171, 0.15)',
          left: isSidebarCondensed ? leftSidebarWidthCollapsed : leftSidebarWidth,
          transition: isSidebarCondensed ? 'all 0.2s ease-out' : '',
          width: `calc(100% - ${isSidebarCondensed ? leftSidebarWidthCollapsed : leftSidebarWidth})`,
          height: topbarHeight,
          zIndex: zIndexTopbar
        }}>
        <Topbar handleFullscreen={handleFullscreen} />
      </AppBar>

      <Box>
        <LeftSidebar isCondensed={isSidebarCondensed} onIsCondensed={handleIsCondensed} />
      </Box>

      <Box
        component="main"
        data-testid="authenticated-layout-wrapper"
        sx={{
          flexGrow: 1,
          overflow: 'auto',
          mt: topbarHeight,
          ml: isSidebarCondensed ? leftSidebarWidthCollapsed : leftSidebarWidth,
          p: `${removeFullPadding ? '0' : `${removeYPadding ? '0' : '8px'} 8px ${removeYPadding ? '0' : '20px'}`}`,
          width: `calc(100% - ${isSidebarCondensed ? leftSidebarWidthCollapsed : leftSidebarWidth})`,
          height: `calc(100vh - ${topbarHeight})`,
          transition: isSidebarCondensed ? 'all 0.2s ease-out' : ''
        }}>
        <FullScreen handle={handleFullscreen}>
          <Suspense
            fallback={
              <Stack alignItems="center" justifyContent="center" height="100vh">
                <CircularProgress />
              </Stack>
            }>
            {children}
          </Suspense>
        </FullScreen>
      </Box>

      {isShowingShortcuts ? <KeyboardShortcuts onClose={() => onShowingShortcuts(false)} /> : null}
    </Box>
  );
};

export default AuthenticatedLayout;
