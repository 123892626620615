import api from './config';
import Company from '@global-interfaces/Company';

const companyAPI = {
  fetch: async (id: number) => api.get<Company>(`companies/${id}/`),
  update: async (id: number, data: Partial<Company>) => api.patch(`companies/${id}/`, data),
  updateLogo: async (id: number, file: string | Blob) => {
    const formData = new FormData();
    formData.append('content', file);
    return api.post(`companies/${id}/upload-logo/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
};

export default companyAPI;
