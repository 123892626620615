import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import { ErrorEvent } from '@sentry/types';
import posthog from 'posthog-js';

import packageInfo from '../../package.json';

const APP_VERSION = packageInfo.version;
const APP_NAME = packageInfo.name;

const sentryConfiguration: Sentry.BrowserOptions = {
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_ENV,
  release: `${APP_NAME}@${APP_VERSION}`,
  ignoreErrors: [
    /^.*Request failed with status code (401|403|404).*$/,
    /^.*Loading chunk.*$/,
    /^.*Loading CSS chunk.*$/,
    /^.*Unable to preload CSS for.*$/,
    /^.*JavaScript MIME type.*$/,
    /^.*getBoundingClientRect.*$/,
    /^.*ChunkLoadError.*$/,
    /^.*Failed to fetch dynamically imported module.*$/,
    /^.*error loading dynamically imported module.*$/,
    /^.*Request failed with status code 401.*$/,
    /^.*Request failed with status code 404.*$/
  ],

  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 1.0,
  tracesSampleRate: 0.3, // Sentry.BrowserTracing
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    }),
    Sentry.replayIntegration({
      networkDetailAllowUrls: [/https?:\/\/(backend|data-nexus|agave-services)(-[\w]+)?\.inbuild\.ai(\/.*)?/],
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false
    })
  ],

  beforeSend(event: ErrorEvent, hint: Sentry.EventHint) {
    const regex = /https?:\/\/(backend|data-nexus|agave-services)(-[\w]+)?\.inbuild\.ai(.*)sync-documents/;

    const originalException = hint?.originalException;

    if (originalException && (originalException as AxiosError).isAxiosError) {
      const axiosError = originalException as AxiosError;
      const statusCode = axiosError.response?.status || axiosError.request?.status;
      const responseURL = axiosError.request?.responseURL;

      // ignore 422 and 500 error from sync-documents
      if (regex.test(responseURL) && (statusCode === 422 || statusCode === 500)) {
        return null;
      }

      // ignore 401 error
      if (statusCode === 401) {
        return null;
      }
    } else if (hint.originalException && (hint.originalException as { status?: number }).status === 401) {
      return null;
    }

    return event;
  }
};

export const initializeSentry = (userId?: number) => {
  if (userId && [24].includes(userId)) {
    Sentry.init({
      ...sentryConfiguration,
      replaysSessionSampleRate: 1
    });
    // ... other logic specific to user
  } else {
    Sentry.init(sentryConfiguration);
  }

  const postHogIntegration = new posthog.SentryIntegration(
    posthog,
    import.meta.env.VITE_SENTRY_ORGANIZATION_NAME,
    Number(import.meta.env.VITE_SENTRY_PROJECT_ID),
    undefined,
    ['error', 'info']
  );

  postHogIntegration.setupOnce(Sentry.addEventProcessor, () => {});
};

export default sentryConfiguration;
